var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-tef-messages",
        title: _vm.titleModal,
        "hide-footer": "",
        scrollable: "",
        centered: "",
        size: "xl",
      },
      on: { show: _vm.onShow },
    },
    [
      _c(
        "b-container",
        [
          _c("b-table", {
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              small: "",
              fields: _vm.fields,
              items: _vm.lastMessages,
              "empty-text": _vm.$t("Sem mensagens"),
              "tbody-tr-class": _vm.rowClass,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(other)",
                fn: function (row) {
                  return [
                    row.item.status
                      ? _c("span", [
                          _c("b", [
                            _vm._v(" " + _vm._s(_vm.$t("Status")) + ":"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.statusTefLabel[row.item.status] ||
                                  row.item.status
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    row.item.details
                      ? _c("span", [
                          _c("b", [
                            _vm._v(" " + _vm._s(_vm.$t("Operação TEF")) + ":"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.operationTefLabel[
                                  row.item.details.operation
                                ] || row.item.details.operation
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    row.item.amount
                      ? _c("span", [
                          _c("b", [
                            _vm._v(" " + _vm._s(_vm.$t("Valor")) + ":"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("currency")(row.item.amount)) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    row.item.nsu
                      ? _c("span", [
                          _c("b", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("NSU Autorizadora")) + ":"
                            ),
                          ]),
                          _vm._v(" " + _vm._s(row.item.nsu) + " "),
                        ])
                      : _vm._e(),
                    row.item.nsuGateway
                      ? _c("span", [
                          _c("b", [
                            _vm._v(" " + _vm._s(_vm.$t("NSU Gateway")) + ":"),
                          ]),
                          _vm._v(" " + _vm._s(row.item.nsuGateway) + " "),
                        ])
                      : _vm._e(),
                    row.item.saleId
                      ? _c("span", [
                          _c("b", [
                            _vm._v(" " + _vm._s(_vm.$t("Venda")) + ":"),
                          ]),
                          _vm._v(_vm._s(row.item.saleId) + " "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("e-color-subtitled", {
                    attrs: {
                      colors: [
                        {
                          backgroundColor: "table-warning",
                          title: _vm.$t("Cancelamento"),
                        },
                        {
                          backgroundColor: "table-danger",
                          title: _vm.$t("Erro"),
                        },
                      ],
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }