<template>
  <div class="h-100 position-relative">
    <template v-if="!hideAside">
      <vue-perfect-scrollbar
        :settings="{
          maxScrollbarLength: 60,
          wheelPropagation: false,
        }"
        class="scroll-area"
        tagname="div"
        @ps-scroll-y="
          (evt) => {
            shallShadowBottom = evt.srcElement.scrollTop > 0
          }
        "
      >
        <ul class="ul-options">
          <li
            v-for="(menu, index) in menus"
            :key="index"
          >
            <pay-box-tef-actions v-if="payBoxActionsEnum.TEF == menu.type" />
            <b-button
              v-else
              :id="menu.elId"
              :variant="menu.disabled ? 'light' : 'outline-light'"
              class="btn-pdv-operation"
              :class="{ 'disabled-opacity': !menu.hasPermission }"
              :title="menu.text"
              :disabled="menu.disabled"
              @click="
                () => onVerifyUserPermission(menu.hasPermission, () => menu.clickAction(menu.type))
              "
            >
              <div class="d-flex flex-column align-items-center justify-content-center">
                <b-icon
                  class="mb-1"
                  scale="1.5"
                  :icon="menu.icon"
                />
                {{ menu.text }}
              </div>
            </b-button>
          </li>
        </ul>
      </vue-perfect-scrollbar>
    </template>

    <div
      v-if="hideAside"
      class="hidden-aside"
    >
      <b-icon
        variant="light"
        scale="1.5"
        :icon="hideAside ? 'ChevronDoubleRight' : 'ChevronDoubleLeft'"
      />
    </div>
  </div>
</template>

<script>
import { BIcon, BButton } from 'bootstrap-vue'
import { app, paymentTypes } from '@/mixins'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { hasErpAccess } from '@/auth/utils'
import { mapGetters } from 'vuex'
import PayBoxTefActions from './PayBoxTefActions.vue'

export default {
  components: { BIcon, BButton, VuePerfectScrollbar, PayBoxTefActions },

  mixins: [app, paymentTypes],

  props: {
    hideAside: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters('pages/pdv/payBoxConfiguration', ['isBarStore', 'isTefEnabled']),

    menus() {
      const { CASH_UP, TEF } = this.payBoxActionsEnum
      return [
        {
          elId: 'paybox_operation',
          icon: 'basket-fill',
          text: this.$t('Caixa'),
          clickAction: () => {
            this.$router.push({ name: 'pdv-pay-box' })
          },
          type: '',
          disabled: this.$route.name === 'pdv-pay-box',
          hasPermission: true,
          show: true,
        },
        {
          elId: 'btn_paybox_bar_tabs',
          icon: 'clipboard',
          text: this.$t('Gerenciar comandas'),
          clickAction: () => {
            this.$router.push({ name: 'pdv-pay-box-bar-tab-management' })
          },
          type: '',
          disabled: this.$route.name === 'pdv-pay-box-bar-tab-management',
          hasPermission:
            this.$can('Read', 'BarTabConsumption') &&
            this.$can('ManageBarTab', 'BarTabConsumption'),
          show: this.isBarStore && this.$can('ManageBarTab', 'BarTabConsumption'),
        },
        {
          elId: 'btn_paybox_delivery',
          icon: 'clockHistory',
          text: this.$t('Vendas pendentes'),
          clickAction: () => {
            this.$router.push({ name: 'pdv-pay-box-delivery-sales' })
          },
          type: '',
          disabled: this.$route.name === 'pdv-pay-box-delivery-sales',
          hasPermission: this.$can('Search', 'Sale'),
          show: true,
        },
        {
          elId: 'btn_paybox_reclaim_product',
          icon: 'bootstrap-reboot',
          text: this.$t('Retornar produto'),
          clickAction: () => {
            this.$router.push({ name: 'pdv-pay-box-reclaim-products' })
          },
          type: '',
          disabled: this.$route.name === 'pdv-pay-box-reclaim-products',
          hasPermission: this.$can('Search', 'Sale'),
          show: true,
        },
        {
          elId: 'paybox_consumption_history',
          icon: 'clipboard-data',
          text: this.$t('Histórico de consumos'),
          clickAction: () => {
            this.$router.push({ name: 'pdv-pay-box-bar-tab-consumption-history' })
          },
          type: '',
          disabled: this.$route.name === 'pdv-pay-box-bar-tab-consumption-history',
          hasPermission: this.$can('ReadHistory', 'BarTabConsumption'),
          show: this.isBarStore && this.$can('ReadHistory', 'BarTabConsumption'),
        },
        {
          elId: 'paybox_manage_sales',
          icon: 'list-nested',
          text: this.$t('Gerenciar vendas'),
          clickAction: () => {
            this.$router.push({ name: 'pdv-pay-box-manage-sales' })
          },
          type: '',
          disabled: this.$route.name === 'pdv-pay-box-manage-sales',
          hasPermission: this.$can('Search', 'Sale'),
          show: true,
        },
        {
          elId: 'cash_up',
          icon: 'BoxArrowUpRight',
          text: this.payBoxActionsDesc[CASH_UP],
          clickAction: this.onShowSidebar,
          type: CASH_UP,
          disabled: false,
          hasPermission: this.$can('CreateBleeding', 'CashBook'),
          show: true,
        },
        {
          elId: 'accounts_payable',
          icon: 'Cash',
          text: this.$t('Despesas'),
          clickAction: () => {
            this.$router.push({ name: 'pdv-pay-box-accounts-payable' })
          },
          type: '',
          disabled: this.$route.name === 'pdv-pay-box-accounts-payable',
          hasPermission: this.$can('Read', 'PayBoxAccountsPayable'),
          show: true,
        },
        {
          elId: 'btn_paybox_statement',
          icon: 'receipt',
          text: this.$t('Extrato do caixa'),
          clickAction: () => {
            this.$router.push({ name: 'pdv-pay-box-statement' })
          },
          type: '',
          disabled: this.$route.name === 'pdv-pay-box-statement',
          hasPermission: this.$can('GetActiveTransactions', 'CashBook'),
          show: true,
        },
        {
          elId: 'btn_pay_box_configuration',
          icon: 'gear',
          text: this.$t('Config. do PDV'),
          clickAction: () => {
            this.$router.push({ name: 'pdv-pay-box-configuration' })
          },
          type: '',
          disabled: this.$route.name === 'pdv-pay-box-configuration',
          hasPermission: this.$can('Setup', 'PayBox'),
          show: this.$can('Setup', 'PayBox'),
        },
        {
          type: TEF,
          hasPermission: true,
          show: this.isTefEnabled,
        },
        {
          elId: 'paybox_close',
          icon: 'BoxArrowRight',
          text: this.$t('Fechar Caixa'),
          clickAction: () => {
            this.$router.push({ name: 'pdv-pay-box-close' })
          },
          type: '',
          disabled: false,
          hasPermission: true,
          show: true,
        },
        {
          elId: 'paybox_back_erp',
          icon: 'ArrowBarLeft',
          text: this.$t('Voltar ao ERP'),
          clickAction: () => {
            this.$router.push({ name: 'home' })
          },
          type: '',
          disabled: false,
          hasPermission: hasErpAccess(),
          show: hasErpAccess(),
        },
      ].filter(t => t.show)
    },
  },

  methods: {
    onVerifyUserPermission(hasPermission, callback) {
      if (!hasPermission) {
        this.showError({
          title: this.$t('Acesso negado'),
          message: this.$t('Você não possui acesso a esta funcionalidade.'),
        })
        return
      }

      callback()
    },

    onShowSidebar(type) {
      this.$emit('show-sidebar', type)
    },
  },
}
</script>

<style scoped lang="scss">
.disabled-opacity {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.brand-logo {
  height: 50px;
  width: 50px;
  margin: 0 auto;
}

.hidden-aside {
  position: absolute;
  right: 5px;
  bottom: 15px;
}

.btn-pdv-operation {
  width: 90px;
  height: 90px;
  padding: 10px 10px;
}

.scroll-area {
  position: relative;
  margin: 0;
  // width is col1 + col2 of grid layout
  width: 345px !important;
  height: calc(100vh - 50px) !important;

  padding: 20px 0px;
}

.ul-options {
  padding: 0px 15px 0px;
  list-style: none;

  display: flex;
  flex-flow: wrap row;
  align-items: flex-start;
  gap: 20px;
}
</style>
