<template>
  <b-dropdown
    boundary="viewport"
    :variant="isDark ? 'outline-dark' : 'outline-light'"
    menu-class="position-fixed"
    class="pdv-dropdown"
    no-caret
  >
    <template #button-content>
      <div class="d-flex flex-column align-items-center justify-content-center">
        <b-icon
          class="mb-1"
          scale="1.5"
          icon="credit-card-2-front"
        />
        TEF
      </div>
    </template>

    <b-dropdown-item @click="printLastReceipt">
      <b-icon
        icon="printer"
        variant="outline-primary"
        font-scale="1"
      />
      <span class="dropdown-text">{{ $t('Reimpressão último comprovante') }}</span>
    </b-dropdown-item>

    <b-dropdown-item @click="showMessagesModal">
      <b-icon
        icon="card-list"
        variant="outline-primary"
        font-scale="1"
      />
      <span class="dropdown-text">{{ $t('Visualizar mensagens/alertas') }}</span>
    </b-dropdown-item>

    <b-dropdown-item @click="refreshTables">
      <feather-icon
        icon="DownloadCloudIcon"
        size="16"
        class="text-body align-middle mr-25"
      />
      <span class="dropdown-text">{{ $t('Atualizar tabelas') }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="canRestartClient"
      :title="$t('PAY_BOX_SALE.TEF.INSTRUCTION_TEF_SERVICE_RESTART')"
      @click="restartService"
    >
      <b-icon
        icon="gear"
        variant="outline-primary"
        font-scale="1"
      />
      <span class="dropdown-text">{{ $t('Reiniciar serviço (PINPAD)') }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem, BIcon } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import { loadingOverlay } from '@/mixins'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BIcon,
  },
  mixins: [loadingOverlay],
  props: {
    isDark: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapState('pages/pdv/payBoxConfiguration', ['payBox']),
    canRestartClient() {
      return this.payBox?.tef?.restartClientMode !== 'Always'
    },
  },
  methods: {
    ...mapActions('pages/pdv/payBoxTef', {
      stPrintLastReceipt: 'printLastReceipt',
      stRefreshTables: 'refreshTables',
      stRestartService: 'restartService',
    }),
    showMessagesModal() {
      this.$bvModal.show('modal-tef-messages')
    },
    async printLastReceipt() {
      try {
        await this.stPrintLastReceipt()
      } catch (error) {
        this.showError({ error })
      }
    },
    async refreshTables() {
      try {
        await this.stRefreshTables()
      } catch (error) {
        this.showError({ error })
      }
    },
    async restartService() {
      try {
        await this.stRestartService()
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-text {
  margin-left: 5px;
}

.pdv-dropdown {
  width: 90px;
  height: 90px;
  padding: 0;
}
</style>
