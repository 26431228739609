<template>
  <b-modal
    id="modal-tef-messages"
    :title="titleModal"
    hide-footer
    scrollable
    centered
    size="xl"
    @show="onShow"
  >
    <b-container>
      <b-table
        show-empty
        responsive
        striped
        small
        class="bordered"
        :fields="fields"
        :items="lastMessages"
        :empty-text="$t('Sem mensagens')"
        :tbody-tr-class="rowClass"
      >
        <template #cell(other)="row">
          <span v-if="row.item.status">
            <b> {{ $t('Status') }}:</b>
            {{ statusTefLabel[row.item.status] || row.item.status }}
          </span>
          <span v-if="row.item.details">
            <b> {{ $t('Operação TEF') }}:</b>
            {{ operationTefLabel[row.item.details.operation] || row.item.details.operation }}
          </span>
          <span v-if="row.item.amount">
            <b> {{ $t('Valor') }}:</b> {{ row.item.amount | currency }}
          </span>
          <span v-if="row.item.nsu">
            <b> {{ $t('NSU Autorizadora') }}:</b> {{ row.item.nsu }}
          </span>
          <span v-if="row.item.nsuGateway">
            <b> {{ $t('NSU Gateway') }}:</b> {{ row.item.nsuGateway }}
          </span>
          <span v-if="row.item.saleId">
            <b> {{ $t('Venda') }}:</b>{{ row.item.saleId }}
          </span>
        </template>
      </b-table>

      <b-row>
        <b-col cols="12">
          <e-color-subtitled
            :colors="[
              { backgroundColor: 'table-warning', title: $t('Cancelamento') },
              { backgroundColor: 'table-danger', title: $t('Erro') },
            ]"
          />
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { BTable, BModal, BRow, BCol, BContainer } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import EColorSubtitled from '@/views/components/EColorSubtitled.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BModal,
    BContainer,
    EColorSubtitled,
  },
  data() {
    return {
      operationTefLabel: {
        Credit: this.$t('Crédito'),
        CreditCancelation: this.$t('Cancelar crédito'),
        Debit: this.$t('Débito'),
        DebitCancelation: this.$t('Cancelar débito'),
        Pix: this.$t('PIX'),
        PixCheckStatus: this.$t('Consultar status PIX'),
        ReprintLastCoupon: this.$t('Reimpressão último comprovante'),
        ConfigClient: this.$t('Configuração'),
        Authenticate: this.$t('Autenticação terminal'),
        AskForData: this.$t('Requisitar dados'),
        GenericCancelation: this.$t('Cancelamento genérico'),
      },
      eventTypeLabel: {
        CANCEL_TRANSACTION: this.$t('Cancelar transação'),
        NEW_TRANSACTION: this.$t('Nova transação'),
        CONFIGURATION: this.$t('Configuração'),
        MESSAGE: this.$t('Mensagem'),
      },
      transactionTypeLabel: {
        Credit: this.$t('Crédito'),
        Debit: this.$t('Débito'),
        Pix: this.$t('Pix'),
      },
      statusTefLabel: {
        SUCCESS: this.$t('Sucesso'),
        ERROR: this.$t('Erro'),
        REJECTED: this.$t('Rejeitado'),
        CANCELED_BY_USER: this.$t('Cancelada no PINPAD'),
      },
    }
  },
  computed: {
    ...mapState('pages/pdv/payBoxTef', ['lastMessagesToKeep', 'lastMessages']),
    ...mapState('pages/pdv/payBoxConfiguration', ['payBox']),
    titleModal() {
      return this.$t(`Últimas mensagens (${this.lastMessagesToKeep}) TEF`)
    },
    fields() {
      return [
        {
          label: this.$t('Data/hora'),
          key: 'dateTime',
          formatter: value => this.$options.filters.datetime(value, null, true),
          sortable: true,
          thStyle: { width: '160px' },
        },
        {
          label: this.$t('Tipo'),
          key: 'eventType',
          thStyle: { width: '150px' },
          formatter: (value, key, item) =>
            item.transactionType
              ? `${this.eventTypeLabel[item.eventType]} (${
                  this.transactionTypeLabel[item.transactionType]
                })`
              : this.eventTypeLabel[item.eventType],
        },
        {
          label: this.$t('Mensagem'),
          key: 'message',
          formatter: value => this.$t(value),
        },
        {
          label: this.$t('Outras infos'),
          key: 'other',
          thStyle: { width: '255px' },
        },
      ]
    },
  },
  methods: {
    ...mapActions('pages/pdv/payBoxTef', ['restoreLastMessages']),
    async onShow() {
      await this.restoreLastMessages()
    },
    rowClass(item) {
      if (
        item?.eventType === 'CANCEL_TRANSACTION' ||
        (item?.details?.operation ? item?.details?.operation.endsWith('Cancelation') : false)
      )
        return 'table-warning'
      if (item?.status === 'ERROR') return 'table-danger'
      return ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
