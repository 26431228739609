import { render, staticRenderFns } from "./PayBoxAside.vue?vue&type=template&id=36990a52&scoped=true&"
import script from "./PayBoxAside.vue?vue&type=script&lang=js&"
export * from "./PayBoxAside.vue?vue&type=script&lang=js&"
import style0 from "./PayBoxAside.vue?vue&type=style&index=0&id=36990a52&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36990a52",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/echope/echope-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36990a52')) {
      api.createRecord('36990a52', component.options)
    } else {
      api.reload('36990a52', component.options)
    }
    module.hot.accept("./PayBoxAside.vue?vue&type=template&id=36990a52&scoped=true&", function () {
      api.rerender('36990a52', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/pdv/pay-box/components/PayBoxAside.vue"
export default component.exports