var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      staticClass: "dropdown-user",
      attrs: {
        right: "",
        "toggle-class": "d-flex align-items-center dropdown-user-link",
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _c("div", { staticClass: "d-sm-flex d-none user-nav" }, [
                _c(
                  "p",
                  {
                    staticClass: "user-name font-weight-bolder mb-0",
                    attrs: { "data-test": "UsernameMenu" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.loggedUser.fullName || _vm.loggedUser.username
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "user-status",
                    staticStyle: { "margin-right": "10px" },
                  },
                  [_vm._v(" | " + _vm._s(_vm.payBoxDescription) + " ")]
                ),
              ]),
              _c(
                "b-avatar",
                {
                  staticClass: "badge-minimal",
                  attrs: {
                    size: "40",
                    src: _vm.loggedUser.avatar,
                    variant: "light-primary",
                    badge: "",
                    "badge-variant": "success",
                  },
                },
                [
                  !_vm.loggedUser.fullName
                    ? _c("feather-icon", {
                        attrs: { icon: "UserIcon", size: "22" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-dropdown-text",
        {
          attrs: {
            to: { name: "pages-profile" },
            "link-class": "d-flex align-items-center",
          },
        },
        [
          _c("span", [
            _c("b", [_vm._v(_vm._s(_vm.$t("Usuário")) + ": ")]),
            _vm._v(_vm._s(_vm.loggedUser.fullName)),
          ]),
        ]
      ),
      _c(
        "b-dropdown-text",
        {
          attrs: {
            to: { name: "apps-todo" },
            "link-class": "d-flex align-items-center",
          },
        },
        [
          _c("span", [
            _c("b", [_vm._v(_vm._s(_vm.$t("Loja")) + ": ")]),
            _vm._v(_vm._s(_vm.nomeFantasiaEmpresaLogada)),
          ]),
        ]
      ),
      _c(
        "b-dropdown-text",
        {
          attrs: {
            to: { name: "apps-email" },
            "link-class": "d-flex align-items-center",
          },
        },
        [
          _c("span", [
            _c("b", [_vm._v(_vm._s(_vm.$t("CNPJ")) + ": ")]),
            _vm._v(_vm._s(_vm.cnpjEmpresaLogada)),
          ]),
        ]
      ),
      _c(
        "b-dropdown-text",
        {
          attrs: {
            to: { name: "apps-chat" },
            "link-class": "d-flex align-items-center",
          },
        },
        [
          _c("span", [
            _c("b", [_vm._v(_vm._s(_vm.$t("Caixa")) + ": ")]),
            _vm._v(_vm._s(_vm.payBoxDescription)),
          ]),
        ]
      ),
      _c("b-dropdown-divider"),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            "link-class": "d-flex align-items-center",
            "data-test": "ResetPasswordButton",
          },
          on: { click: _vm.resetPassword },
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("Alterar senha")))])]
      ),
      _vm.isElectron
        ? _c(
            "b-dropdown-item",
            {
              attrs: {
                "link-class": "d-flex align-items-center",
                "data-test": "ChangeUserButton",
              },
              on: { click: _vm.changeUser },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("Trocar usuário")))])]
          )
        : _vm._e(),
      _c("b-dropdown-divider"),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            "link-class": "d-flex align-items-center",
            "data-test": "LogoutButton",
          },
          on: { click: _vm.exit },
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("Sair")))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }