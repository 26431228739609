var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      title: _vm.sidebarHelper.title,
      show: _vm.showSidebar,
      fetching: _vm.fetching,
      saving: _vm.saving,
      "no-close-on-backdrop": "",
      width: "500px",
    },
    on: { save: _vm.onConfirmSubmit, hidden: _vm.onHiddenSidebar },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              { ref: "pdvSidebarForm", attrs: { name: "pdvSidebarForm" } },
              [
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-between" },
                      [
                        _c("p", [_vm._v("Valor disponível para sangria")]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(_vm.totalOutputCashAvailable)
                            )
                          ),
                        ]),
                      ]
                    ),
                    _vm.typeAction === _vm.payBoxActionsEnum.CASH_UP
                      ? [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  _vm.form.isRefund
                                    ? _c("FormulateInput", {
                                        staticClass: "input-size",
                                        attrs: {
                                          id: "sidebar_bleeding-charge_back_value",
                                          name: "valueChargeBack",
                                          "is-edit": false,
                                          type: "label",
                                          filter: "currency",
                                          label: _vm.$t(
                                            "Valor a estornar (última sangria)"
                                          ),
                                        },
                                        model: {
                                          value: _vm.lastBleeding.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.lastBleeding,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "lastBleeding.value",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "12" } },
                                [
                                  !_vm.form.isRefund
                                    ? _c("FormulateInput", {
                                        ref: "inputValueHelper",
                                        staticClass: "required input-size",
                                        attrs: {
                                          id: "valueHelper",
                                          "is-edit": false,
                                          type: "text-number",
                                          currency: "R$",
                                          precision: 2,
                                          label: _vm.$t("Valor a retirar"),
                                          validation: "required|min:1",
                                        },
                                        model: {
                                          value: _vm.form.value,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "value", $$v)
                                          },
                                          expression: "form.value",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: _vm.hasRefund ? "5" : "12" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "sidebar_bleeding-not_refund_label",
                                      type: "switch",
                                      label: _vm.$t("Estornar sangria?"),
                                    },
                                    on: { change: _vm.onCancelBleeding },
                                    model: {
                                      value: _vm.form.isRefund,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "isRefund", $$v)
                                      },
                                      expression: "form.isRefund",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("FormulateInput", {
                                    staticClass: "required",
                                    attrs: {
                                      type: "textarea",
                                      label: _vm.$t("Observação"),
                                      validation: "required|min:10",
                                    },
                                    model: {
                                      value: _vm.form.observation,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "observation", $$v)
                                      },
                                      expression: "form.observation",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }